import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  getNumberWithCommas(num) {
    if (num == null) return 0;
    var x=num.toString();
    var lastThree = x.substring(x.length-3);
    var otherNumbers = x.substring(0,x.length-3);
    if(otherNumbers != '')
        lastThree = ',' + lastThree;
    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  }

  getToday() {
    let d = new Date();
    return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + (d.getDate() + 1);
  }

}
