import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private apiURL = "https://apis.sandhyavandanam.org/drishti";
  public profileURL = 'https://master.sandhyavandanam.org/pages/';

  constructor(private http: HttpClient, private session: SessionService) { }

  getHeaders(){
    let token = this.session.getToken();
    let httpOptions = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Bearer '+token,
      'Member': this.session.getID().toString()
    });    
    return httpOptions;
  }

  login(username: string, password: string) {
    return this.http.post(this.apiURL+'/login.php', {
      username: username,
      password: password
    });
  }

  verifyToken(id: number, token: string) {
    return this.http.post(this.apiURL+'/verifyToken.php', {
      id: id,
      token: token
    });
  }

  logout(id: number) {
    return this.http.post(this.apiURL+'/dropToken.php', {
      id: id
    }, { headers: this.getHeaders() });
  }

  getMembers() {
    return this.http.get(this.apiURL+'/getMembers.php', {
      headers: this.getHeaders()
    });
  }

  search(query){
    return this.http.get(this.apiURL+'/searchData.php?q='+query, { headers: this.getHeaders() });
  }

  addMember(firstname, lastname, gender, gothram, dob, countryCode, mobile, email, address1, address2, city, district, state, pincode, country) {
    return this.http.post(this.apiURL+'/addMember.php', {
      firstname: firstname,
      lastname: lastname,
      gender: gender,
      gothram: gothram,
      dob: dob,
      countryCode: countryCode,
      mobile: mobile,
      email: email,
      address1: address1,
      address2: address2,
      city: city,
      district: district,
      state: state,
      pincode: pincode,
      country: country
    }, { headers: this.getHeaders() });
  }

  updateJapamCount(update: any, eventId, updatedBy) {
    return this.http.post(this.apiURL+'/updateJapamCount.php', {
      eventId: eventId,
      memberId: update.MemberID,
      japam: update.JapamCount,
      tarpanam: update.TarpanamCount,
      from: update.FromDate,
      to: update.ToDate,
      updatedBy: updatedBy
    }, { headers: this.getHeaders() });
  }

  deleteJapathaEnrollment(eventId, memberId) {
    return this.http.post(this.apiURL+'/deleteJapathaEnrollment.php', {
      eventId: eventId,
      memberId: memberId
    }, { headers: this.getHeaders() });
  }

  getMemberData(id) {
    return this.http.get(this.apiURL+'/getMemberData.php?id='+id, { headers: this.getHeaders() });
  }

  getJapamsList() {
    return this.http.get( this.apiURL+'/getJapamParayanamList.php', { headers: this.getHeaders() });
  }

  getSibiramsList() {
    return this.http.get( this.apiURL+'/getSibiramsList.php', { headers: this.getHeaders() });
  }

  getJapamDetailsList(id) {
    return this.http.get( this.apiURL+'/getJapamDetails.php?id='+id, { headers: this.getHeaders() });
  }

  getSibiramDetails(id) {
    return this.http.get( this.apiURL+'/getSibiramDetails.php?id='+id, { headers: this.getHeaders() } );
  }

  getZeroandMoreCount(id, zone) {
    return this.http.get(this.apiURL+'/getZeroAndMoreCount.php?eventId='+id+'&zone='+zone, { headers: this.getHeaders() });
  }

  getJapamFrequency(eventId, zone) {
    return this.http.get(this.apiURL+'/getJapamFrequency.php?eventId='+ eventId +'&zone='+zone, { headers: this.getHeaders() });
  }

  getNewEenrollmentsFrequency(eventId, zone) {
    return this.http.get(this.apiURL+'/getNewEenrollmentsFrequency.php?eventId='+ eventId +'&zone='+zone, { headers: this.getHeaders() });
  }

  changeZone(eventId, memberId, zone) {
    return this.http.post(this.apiURL+'/changeZone.php', {
      eventId: eventId, 
      memberId: memberId,
      zone: zone
    }, { headers: this.getHeaders() });
  }

  getActiveEvents() {
    return this.http.get(this.apiURL+'/getEvents.php', { headers: this.getHeaders() });
  }

  addVisitor(eventId, first, last, gender, contact, city, memberId, type) {
    return this.http.post(this.apiURL+'/addVisitor.php', {
      eventId: eventId,
      first: first,
      last: last,
      gender: gender,
      contact: contact,
      city: city,
      memberId: memberId,
      type: type
    }, { headers: this.getHeaders() })
  }

}
